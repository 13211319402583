<template>
  <div>
    <h4>链路调度算法</h4>
    <b-button variant="outline-primary" @click="draw">Draw</b-button>
    <br>
    <svg ref="pic"></svg>

  </div>

</template>

<script>
import {link_scheduling, linkGenerate, Point} from "../utils/link_scheduling";
import {getDis} from "../utils/base_algo";
import {drawL, drawP, initDraw} from "../utils/base_d3";

export default {
  name: "LinkScheduling",
  data() {
    return {
      linkList: [],
      sucLinkList: [],
      d3Config: {
        transform: {
          x: 20,
          y: 20
        },
        height: 400,
        width: 400,
        x: {
          start: 0,
          end: 100
        },
        y: {
          start: 0,
          end: 100
        },
        r: 2
      },
      svg: null
    }
  },
  created() {
    this.rGenerate()
  },
  methods: {
    rGenerate() {
      let ll1 = linkGenerate()
      let success_list = [ll1]
      let all_list = [ll1]
      for (let i = 0; i < 500; i++) {
        let ll_t = linkGenerate()
        all_list.push(linkGenerate())

        let ll_t_b = ll_t[0]
        let ll_t_e = ll_t[ll_t.length - 1]

        let ll_t_len = getDis(ll_t_b.x, ll_t_b.y, ll_t_e.x, ll_t_e.y);

        let isSuc = link_scheduling(ll_t_len, success_list, ll_t_e);

        if (isSuc) {
          success_list.push(ll_t)
          // console.log("链路成功")
        } else {
          console.log("链路失败")
        }

      }
      this.linkList = all_list
      this.sucLinkList = success_list

    },
    generate() {
      let t_x1 = [1, 5, 3]
      let t_y1 = [2, 4, 6]
      let t_x2 = [7, 5, 6, 7]
      let t_y2 = [7, 7, 9, 8]
      let t_ll1 = [], t_ll2 = [];
      let pre = new Point(t_x1[0], t_y1[0])
      t_ll1.push(pre)
      for (let i = 1; i < t_x1.length; i++) {
        let t = new Point(t_x1[i], t_y1[i]);
        t.precursor = pre;
        t_ll1.push(t);
        pre = t;
      }
      pre = new Point(t_x2[0], t_y2[0])
      t_ll2.push(pre)
      for (let i = 1; i < t_x2.length; i++) {
        let t = new Point(t_x2[i], t_y2[i]);
        t.precursor = pre;
        t_ll2.push(t);
        pre = t;
      }
      let success_list = [t_ll1]
      let all_list = [t_ll1, t_ll2]
      let t_ll2_b = t_ll2[0]
      let t_ll2_e = t_ll2[t_ll2.length - 1]
      let t_ll2_len = getDis(t_ll2_b.x, t_ll2_b.y, t_ll2_e.x, t_ll2_e.y)
      let isSuc = link_scheduling(t_ll2_len, success_list, t_ll2_e);
      // let ll1 = linkGenerate()
      // let ll2 = linkGenerate()
      // let success_list = [ll1]
      // let all_list = [ll1, ll2]
      // let ll2_b = ll2[0]
      // let ll2_e = ll2[ll2.length - 1]
      // let ll2_len = getDis(ll2_b.x, ll2_b.y, ll2_e.x, ll2_e.y);
      // let isSuc = link_scheduling(ll2_len, success_list, ll2_e);
      if (isSuc) {
        // success_list.push(ll2)
        console.log("链路成功")
      } else {
        console.log("链路失败")
      }
      this.linkList = all_list
      this.sucLinkList = success_list
    },
    draw() {
      this.svg = initDraw(this.$refs.pic, this.d3Config)
      let cList = [
        "233e8b", "233e8b", "a9f1df", "94d0cc", "eec4c4", "f29191", "21094e", "511281", "4ca1a3", "a5e1ad"
      ]
      let ll = this.linkList
      for (let m = 0; m < ll.length; m++) {
        for (let i = 0; i < ll[m].length; i++) {
          drawP(this.svg, ll[m][i].x, ll[m][i].y, "#" + cList[m % 10], this.d3Config)
          if (ll[m][i].precursor !== null)
            drawL(this.svg, ll[m][i].x, ll[m][i].y, ll[m][i].precursor.x, ll[m][i].precursor.y, this.d3Config)
        }
      }

    }
  }
}
</script>

<style scoped>

</style>
