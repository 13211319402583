export function getDis(_x1, _y1, _x2, _y2) {
  return Math.sqrt(Math.pow(_x1 - _x2, 2) + Math.pow(_y1 - _y2, 2));
}

export class Point {
  x;
  y;
  color = "white"; //颜色状态，白色只可接收，黑灰只可发送
  precursor = null;//前驱
  /**
   * 构造函数
   * @param{number} _x x坐标
   * @param{number} _y y坐标
   */
  constructor(_x, _y) {
    this.x = _x;
    this.y = _y;
  }


  /**
   * 设置前驱
   * @param {Point} p 前驱节点
   */
  setPrecursor(p) {
    if (p.precursor === null)
      p.precursor = this
  }

  /**
   * 获取等待时间, 等待时间与距离成反比
   * @param p 连接节点
   */
  getWaitTime(p) {
    let dis = getDis(this.x, this.y, p.x, p.y)
    return 1 / dis;
  }

  /**
   * 获取发送到达时间
   * @param p 发送到的点
   * @return {number} 发送到达时间
   */
  getFindTime(p) {
    return getDis(this.x, this.y, p.x, p.y);
  }

  /**
   * 发送消息
   * @param pList 点数组
   */
  find(pList) {
    for (let i = 0; i < pList.length; i++) {
      if (getDis(this.x, this.y, pList[i].x, pList[i].y) >= 1.5)
        //应该有个广播范围
        continue
      if (pList[i].color === "white") {
        let t = this.getFindTime(pList[i]) * 1000 * 1
        console.log("🔍️准备搜索：" + this.x + "," + this.y + "->" + pList[i].x + "," + pList[i].y + ":" + t)
        setTimeout(() => {
          this.findEnd(pList[i], pList)
        }, t)
      }
    }
  }

  /**
   * 打印当前点数组的状态
   * @param pList 点数组
   */
  pListStatus(pList) {
    for (let i = 0; i < pList.length; i++) {
      console.log("🤡当前状态：" + pList[i].x + "," + pList[i].y + ": " + pList[i].color)
    }
  }

  /**
   * 收到灰色状态点的消息需要等待，该函数为准备等待的逻辑
   * @param{Point} p 最后找到的点
   * @param{[Point]} pList 点数组
   */
  wait(p, pList) {
    let wt = this.getWaitTime(p) * 1000 * 8
    console.log("🥾等待中：" + p.x + "," + p.y + ": " + p.color + ": " + wt)
    setTimeout(
      () => {
        this.waitEnd(p, pList)
      }, wt
    );
  }

  /**
   * 收到灰色状态点的消息需要等待，该函数为结束后的逻辑
   * @param{Point} p 最后找到的点
   * @param{[Point]} pList 点数组
   */
  waitEnd(p, pList) {
    if (p.color !== "gray") {
      p.color = "black"
      this.isWait = false
      p.find(pList)
    }
    console.log("🏃‍♀️等待结束：" + p.x + "," + p.y + ": " + p.color)
  }

  /**
   * 收到黑色状态点的消息变为灰色，也需要等待一段时间才能发送
   * @param{Point} p 最后找到的点
   * @param{[Point]} pList 点数组
   */
  grayWait(p, pList) {
    console.log("⬛️️灰色等待：" + p.x + "," + p.y + ": " + p.color)
    setTimeout(
      () => {
        p.find(pList)
      }, this.getWaitTime(p) * 1000 * 3
    )
  }

  /**
   * 一个发送到达的函数
   * 计算和所有点的距离到达时间
   * @param{Point} p 最后找到的点
   * @param{[Point]} pList 点数组
   */
  findEnd(p, pList) {
    console.log("😮️搜索结束:" + this.x + "," + this.y + ": " + this.color + "->" + p.x + "," + p.y + ": " + p.color)
    this.setPrecursor(p)
    if (p.color !== "white")
      return
    // 如果收到灰色信号，是等待一段时间后检查自身是否收到黑色信号（收到置为灰色，没收到置为黑色），立即发送
    // 如果收到黑色信号，置为灰色，是等待一段时间后直接发送
    if (this.color === "gray") {
      this.isWait = true
      this.wait(p, pList)
    } else if (this.color === "black") {
      p.color = "gray"
      this.grayWait(p, pList)
    } else {
      console.log("🎈当前颜色是" + this.color)
    }

    this.pListStatus(pList)
  }
}

