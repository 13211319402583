<template>
  <div>
    <h4>链路调度分簇</h4>
    <b-button variant="outline-primary" @click="draw">Draw</b-button>
    <br>
    <svg ref="pic"></svg>
  </div>
</template>

<script>
import {clusterLinkGenerate} from "../utils/link_scheduling";
import {drawCir, drawL, drawP, initDraw} from "../utils/base_d3";

export default {
  name: "LinkSchedulingCluster",
  data() {
    return {
      linkList: [],
      sucLinkList: [],
      d3Config: {
        transform: {
          x: 40,
          y: 40
        },
        height: 400,
        width: 400,
        x: {
          start: 0,
          end: 100
        },
        y: {
          start: 0,
          end: 100
        },
        r: 2
      },
      svg: null,
      radius: 10,
      x_step: 18,
      y_step: 32
    }
  },
  created() {
    this.rGenerate()
  },
  methods: {
    rGenerate() {
      // let ll1 = clusterLinkGenerate(x, y, pointSize, radius / 2)
      // let all_list = [ll1]
      let all_list = []
      let radius = this.radius, pointSize = 10, x = radius, y = radius;
      for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 5; j++) {
          let ll_t = clusterLinkGenerate(x, y, pointSize, radius / 2);
          all_list.push(ll_t)
          x += this.x_step;
          // let ll_t_b = ll_t[0]
          // let ll_t_e = ll_t[ll_t.length - 1]
          //
          // let ll_t_len = getDis(ll_t_b.x, ll_t_b.y, ll_t_e.x, ll_t_e.y);
          //
          // let isSuc = link_scheduling(ll_t_len, success_list, ll_t_e);
          //
          // if (isSuc) {
          //   success_list.push(ll_t)
          //   console.log("链路成功")
          // } else {
          //   console.log("链路失败")
          // }

        }
        y += this.y_step;
        x = radius;


      }
      this.linkList = all_list

    },
    draw() {
      this.svg = initDraw(this.$refs.pic, this.d3Config)
      let cList = [
        "233e8b", "233e8b", "a9f1df", "94d0cc", "eec4c4", "f29191", "21094e", "511281", "4ca1a3", "a5e1ad"
      ]
      let ll = this.linkList
      for (let m = 0; m < ll.length; m++) {
        for (let i = 0; i < ll[m].length; i++) {
          if (i === 0) {
            drawCir(this.svg, ll[m][i].x, ll[m][i].y, this.radius * 2, "#" + cList[m % 10], this.d3Config)
          }
          drawP(this.svg, ll[m][i].x, ll[m][i].y, "#" + cList[m % 10], this.d3Config)
          if (ll[m][i].precursor !== null)
            drawL(this.svg, ll[m][i].x, ll[m][i].y, ll[m][i].precursor.x, ll[m][i].precursor.y, this.d3Config)
        }
      }

    },
  }
}
</script>

<style scoped>

</style>
