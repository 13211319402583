//基础算法
/**
 * 获取两点（p1，p2）距离, get distance
 * @param _x1 p1的x
 * @param _y1 p1的y
 * @param _x2 p2的x
 * @param _y2 p2的y
 * @return {number}
 */
export function getDis(_x1, _y1, _x2, _y2) {
  return Math.sqrt(Math.pow(_x1 - _x2, 2) + Math.pow(_y1 - _y2, 2));
}

/**
 * 获取一定范围的一个随机数,getRandomIntInclusive
 * @param{number} min 最小值
 * @param{number} max 最大值
 * @return {number} 随机数
 */
export function getRInc(min, max) {
  return Math.random() * (max - min) + min;
}

