import * as d3 from "d3";
//
// const d3Config = {
//   transform: {
//     x: 20,
//     y: 20
//   },
//   height: 400,
//   width: 400,
//   x: 100,
//   y: 100,
//   r: 5
// }

export function initDraw(pic, d3Config) {
  let svg = d3.select(pic)
    .classed("axis", true)
    .attr("width", d3Config.width + 200)
    .attr("height", d3Config.height + 200)

  let x_scale = d3.scaleLinear()
    .domain([d3Config.x.start, d3Config.x.end])
    .range([0, d3Config.width])

  let y_scale = d3.scaleLinear()
    .domain([d3Config.y.start, d3Config.y.end])
    .range([0, d3Config.height])

  let x_axis = d3.axisTop()
    .scale(x_scale)

  let x_axis_b = d3.axisBottom()
    .scale(x_scale)
  let y_axis_r = d3.axisRight()
    .scale(y_scale)

  let y_axis = d3.axisLeft()
    .scale(y_scale)

  svg.append("g")
    .attr("transform",
      `translate(${d3Config.transform.x}, ${d3Config.transform.y})`)
    .call(x_axis)

  svg.append("g")
    .attr("transform",
      `translate(${d3Config.transform.x}, ${d3Config.transform.y + d3Config.height})`)
    .call(x_axis_b)

  svg.append("g")
    .attr("transform",
      `translate(${d3Config.transform.x}, ${d3Config.transform.y})`)
    .call(y_axis)

  svg.append("g")
    .attr("transform",
      `translate(${d3Config.transform.x + d3Config.width}, ${d3Config.transform.y})`)
    .call(y_axis_r)
  return svg;
}

export function axisChange(x, y, d3Config) {
  x = x - Math.min(d3Config.x.start, d3Config.x.end)
  y = y - Math.min(d3Config.y.start, d3Config.y.end)

  let x_dis = d3Config.x.start - d3Config.x.end
  let y_dis = d3Config.y.start - d3Config.y.end
  if (x_dis < 0) {
    x_dis = Math.abs(x_dis)
  } else {
    x = x_dis - x;
  }
  if (y_dis < 0) {
    y_dis = Math.abs(y_dis)
  } else {
    y = y_dis - y;
  }
  return {x, y, x_dis, y_dis};
}

export function drawP(svg, x, y, color, d3Config) {
  let r = d3Config.r;

  let axis = axisChange(x, y, d3Config)
  let {x_dis, y_dis} = axis;
  x = axis.x
  y = axis.y
  svg
    .append("circle")
    .attr("r", r)
    .attr("cx", x * d3Config.width / x_dis)
    .attr("cy", y * d3Config.height / y_dis)
    .attr("transform",
      `translate(${d3Config.transform.x - r / 2},
              ${d3Config.transform.y - r / 2})`)
    .attr("fill", color)
}

export function drawL(svg, x1, y1, x2, y2, d3Config) {
  let r = d3Config.r;


  let axis = axisChange(x1, y1, d3Config)
  let {x_dis, y_dis} = axis;
  x1 = axis.x
  y1 = axis.y
  axis = axisChange(x2, y2, d3Config)

  x2 = axis.x
  y2 = axis.y

  svg.append("line")
    .attr("transform",
      `translate(${d3Config.transform.x}, ${d3Config.transform.y})`)
    .style("stroke", "gray")
    .attr("x1", x1 * d3Config.width / x_dis - r / 2)
    .attr("x2", x2 * d3Config.height / x_dis - r / 2)
    .attr("y1", y1 * d3Config.width / y_dis - r / 2)
    .attr("y2", y2 * d3Config.height / y_dis - r / 2)
}


export function drawCir(svg, x, y, radius, color, d3Config) {
  let r = d3Config.r;
  let x_dis = Math.abs(d3Config.x.start - d3Config.x.end)
  let y_dis = Math.abs(d3Config.y.start - d3Config.y.end)
  svg.append("circle")
    .attr("transform",
      `translate(${d3Config.transform.x}, ${d3Config.transform.y})`)
    .style("stroke", color)
    .style("fill", "white")
    .attr("cx", x * d3Config.width / x_dis - r / 2)
    .attr("cy", y * d3Config.height / y_dis - r / 2)
    .attr("r", radius)
}
