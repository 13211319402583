<template>
  <div id="app">
    <h4>Top Discovery算法</h4>
    <b-button variant="outline-primary" @click="draw">Draw</b-button>
    <br>
    <svg ref="pic"></svg>
  </div>
</template>

<script>
import {Point} from "../utils/top_disc"
import * as d3 from 'd3'

export default {
  name: 'App',
  components: {},
  created() {
    this.top_disc()
    setInterval(this.draw, 1000 * 3)
  },
  mounted() {

  },
  data() {
    return {
      svg: null,
      pointList: [],
      d3Config: {
        transform: {
          x: 20,
          y: 20
        },
        height: 400,
        width: 400,
        x: 10,
        y: 10,
        r: 5
      }
    }
  },
  methods: {
    top_disc() {
      //5个点
      // let mockPointList = [
      //   {x: 2.1, y: 1},
      //   {x: 1, y: 1},
      //   {x: 3, y: 2},
      //   {x: 4, y: 3},
      //   {x: 4, y: 2}
      // ]
      // for (let i = 0; i < mockPointList.length; i++) {
      //   let tx = mockPointList[i].x
      //   let ty = mockPointList[i].y
      //   this.pointList.push(new Point(tx, ty))
      // }
      for (let i = 0; i < 200; i++) {
        let tx = this.getRandomIntInclusive(0, this.d3Config.x)
        let ty = this.getRandomIntInclusive(0, this.d3Config.y)
        this.pointList.push(new Point(tx, ty))
      }
      this.pointList[0].color = "black" // 初始化第一个节点为黑色
      this.pointList[0].find(this.pointList)
    },
    getRandomIntInclusive(min, max) {
      return Math.random() * (max - min) + min;
    },
    draw() {
      this.initDraw()
      let pl = this.pointList
      for (let i = 0; i < pl.length; i++) {
        this.drawP(this.svg, pl[i].x, pl[i].y, pl[i].color)
        if (pl[i].precursor !== null)
          this.drawL(this.svg, pl[i].x, pl[i].y, pl[i].precursor.x, pl[i].precursor.y)
      }
    }
    ,
    initDraw() {
      let svg = d3.select(this.$refs.pic)
          .classed("axis", true)
          .attr("width", this.d3Config.width + 200)
          .attr("height", this.d3Config.height + 200)
      this.svg = svg
      let x_scale = d3.scaleLinear()
          .domain([0, this.d3Config.x])
          .range([0, this.d3Config.width])
      let y_scale = d3.scaleLinear()
          .domain([0, this.d3Config.y])
          .range([0, this.d3Config.height])
      let x_axis = d3.axisTop()
          .scale(x_scale)

      let y_axis = d3.axisLeft()
          .scale(y_scale)
      svg.append("g")
          .attr("transform",
              `translate(${this.d3Config.transform.x}, ${this.d3Config.transform.y})`)
          .call(x_axis)
      svg.append("g")
          .attr("transform",
              `translate(${this.d3Config.transform.x}, ${this.d3Config.transform.y})`)
          .call(y_axis)
    }
    ,
    drawP(svg, x, y, color) {
      let r = this.d3Config.r;
      svg
          .append("circle")
          .attr("r", r)
          .attr("cx", x * this.d3Config.width / this.d3Config.x)
          .attr("cy", y * this.d3Config.height / this.d3Config.y)
          .attr("transform",
              `translate(${this.d3Config.transform.x - r / 2},
              ${this.d3Config.transform.y - r / 2})`)
          .attr("fill", color)
    }
    ,
    drawL(svg, x1, y1, x2, y2) {
      let r = this.d3Config.r;
      svg.append("line")
          .attr("transform",
              `translate(${this.d3Config.transform.x}, ${this.d3Config.transform.y})`)
          .style("stroke", "gray")
          .attr("x1", x1 * this.d3Config.width / this.d3Config.x - r / 2)
          .attr("x2", x2 * this.d3Config.height / this.d3Config.y - r / 2)
          .attr("y1", y1 * this.d3Config.width / this.d3Config.x - r / 2)
          .attr("y2", y2 * this.d3Config.height / this.d3Config.y - r / 2)
    }
  }

}
</script>

<style>

</style>
