import {getDis, getRInc} from "./base_algo";

//thinking: 在圆内生成点
/**
 * 分簇链路生成
 *
 * @param{number} max 最大
 * @param{number} min 最小
 * @param{number} pointSize 生成点数
 * @param{number} radius 半径
 */
export function clusterLinkGenerate(max, min, pointSize, radius) {
  // let max = 100;
  // let min = 0;
  // let pointSize = 10;
  // let radius = 2.5;
  let x = getRInc(min - radius, min + radius);
  let y = getRInc(max - radius, max + radius);
  let pre = new Point(x, y);
  let linkList = [pre]
  for (let i = 0; i < pointSize; i++) {
    let tx = getRInc(x - radius, x + radius);
    let ty = getRInc(y - radius, y + radius);
    let dis = getDis(x, y, tx, ty);
    while (dis > radius) {
      tx = getRInc(x - radius, x + radius);
      ty = getRInc(y - radius, y + radius);
      dis = getDis(x, y, tx, ty);
    }
    let tp = new Point(tx, ty);
    tp.precursor = pre;
    pre = tp;
    linkList.push(tp);
  }
  return linkList;

}

// 生成点
// 通过点生成链路
// 判断是否达标
/**
 * 链路生成
 * @return {Point[]}
 */
export function linkGenerate() {


  let max = 100;
  let min = 0
  let step = 10;

  let x = getRInc(min, max);
  let y = getRInc(min, max);

  let pre = new Point(x, y);
  let linkList = [pre]
  for (let i = 0; i < 5; i++) {
    step = getRInc(5, 15);
    let tx = getRInc(pre.x - step < 0 ? 0 : pre.x - step, pre.x + step > 100 ? 100 : pre.x + step);
    let ty = getRInc(pre.y - step < 0 ? 0 : pre.y - step, pre.y + step > 100 ? 100 : pre.y + step);
    if (tx < min || tx > max || ty < min || ty > max) {
      break;
    }
    let tp = new Point(tx, ty);
    tp.precursor = pre;
    pre = tp;
    linkList.push(tp);
  }
  return linkList;
}

export class Point {
  x;
  y;
  precursor = null;//前驱
  /**
   * 构造函数
   * @param{number} _x x坐标
   * @param{number} _y y坐标
   */
  constructor(_x, _y) {
    this.x = _x;
    this.y = _y;
  }
}

/**
 * 链路调度算法
 * @param{number} d_ii 链路长度(首尾距离)
 * @param{[[Point]]} d_j_list 成功链路列表
 * @param{Point} d_i_end 目标链路接收端
 * @return {boolean} 该链路是否是成功通信的链路
 */
export function link_scheduling(d_ii, d_j_list, d_i_end) {
  /**
   * 网络规模：100×100；
   * 链路数量：500；
   * 解码阈值：1.2；
   * 传输(发送)速率：1mW；
   * 背景噪声：10^-6；
   * 路径损耗：4；
   * p发送功率，d_ii链路长度，a路径损耗,d_ji其他所有发送成功链路（发送端）到本链路（接收端）的距离（成功链路到本条链路的尾），u是背景噪声
   */
  let p = 1;
  let a = 4;
  let u = Math.pow(10, -6);
  let ac = accumulate(d_j_list, d_i_end);
  let re = (p * Math.pow(d_ii , -a)) / (p * Math.pow(ac, -a) + u)
  // console.log(re)
  return re > 1.2
}

/**
 * 累加成功链路发送端到目标链路接收端的距离
 * @param{[[Point]]} d_j_list 成功链路列表
 * @param{Point} d_i_end 目标链路接收端
 * @return{number}
 */
function accumulate(d_j_list, d_i_end) {
  let sum = 0;
  for (let i = 0; i < d_j_list.length; i++) {
    sum += getDis(d_j_list[i][0].x, d_j_list[i][0].y, d_i_end.x, d_i_end.y)
  }
  return sum
}
