import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import TopDisc from "./pages/TopDisc";
import LinkScheduling from "./pages/LinkScheduling";
import Home from "./pages/Home";
import LinkSchedulingCluster from "./pages/LinkSchedulingCluster";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import LinkSchedulingDistributed from "./pages/LinkSchedulingDistributed";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueRouter)
Vue.config.productionTip = false
const routes = [
  {
    path: "/",
    component: Home
  },
  {
    path: "/top_disc",
    component: TopDisc
  }, {
    path: "/link_scheduling",
    component: LinkScheduling
  }, {
    path: "/link_scheduling_cluster",
    component: LinkSchedulingCluster
  }, {
    path: "/link_scheduling_distributed",
    component: LinkSchedulingDistributed
  }
]
const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
})
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
