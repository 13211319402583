<template>
<div>
  <b-jumbotron header="WSNE" lead="Wireless Sensor Network Emulation">
    <p>无线传感器网络仿真</p>
  </b-jumbotron>
</div>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped>

</style>
